import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import useSiteSettings from "~utils/useSiteSettings"
import formatPageTitle from "~utils/formatPageTitle"

const Seo = ({ description, lang, meta, title, metaTitle, image, url }) => {

  const { siteTitle, siteSubtitle, siteMetaTitle, siteMetaDescription, siteSocialImage } = useSiteSettings()
  const metaImage = image || siteSocialImage?.asset.url
  const metaDescription = description || siteMetaDescription
  const formattedTitle = formatPageTitle(title, metaTitle, siteTitle, siteMetaTitle, siteSubtitle)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={formattedTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: formattedTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: formattedTitle,
        },
				{
          name: ``,
          content: metaDescription,
        },
        {
          name: 'google-site-verification',
          content: 'bBErx8W2DT9JW3zLbfiETuVQ5trbP08wNTwdOG-tOaQ'
        }
      ].concat(meta)}
    >
			{url &&
				<link rel="canonical" href={`https://misura.com.au${url}`} />
			}
			
		</Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
}

export default Seo
