const formatPageTitle = (title, metaTitle, siteTitle, siteMetaTitle, siteSubtitle) => {
  if(metaTitle){
    return metaTitle
  }
  if(title === "Home" || title === siteTitle){
    return `${siteMetaTitle || siteTitle.toUpperCase()}${siteSubtitle ? ` | ${siteSubtitle}` : ''}`
  }
  if(title){
    return `${title} | ${siteMetaTitle || siteTitle.toUpperCase()}`
  }
  return siteTitle
}

export default formatPageTitle