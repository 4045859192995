import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import RichText from '~components/RichText'
import Seo from "~components/Seo"
import { moduleLayoutStyles } from "~templates/Article"
import Section from '~components/Section'
import Image from '~components/Image'


const Page = ({ data, pageContext }) => {

  const {mobile} = breakpoints
  const page = data.sanityPage

  return (
    <div css={css`
      padding-top: 21px;
      ${mobile}{
        padding-top: 16px;
      }
    `}>
      <Seo
        title={page?.title}
        image={page?.content?.seo?.socialImage?.asset?.url}
        description={page?.content?.seo?.metaDescription}
        metaTitle={page?.content?.seo?.metaTitle}
				url={'/' + pageContext.canonical}
      />
      <Section>
        <h1 css={css`
          grid-column: span 12;
          margin-bottom: 73px;
          ${mobile}{
            margin-bottom: 52px;
          }
        `}>
          {page.title}
        </h1>
      </Section>
      <Section>
        <div css={css`${moduleLayoutStyles}`}>
          <RichText content={page?.content?.text}/>
        </div>
      </Section>
    </div>
  )
}

export const query = graphql`
  query pageQuery($slug: String){
    sanityPage(slug: {current: {eq: $slug}}) {
      id
      title
      slug {
        current
      }
      content: _rawContent(resolveReferences: {maxDepth:10})
    }
  }
`

Page.propTypes = {
}

Page.defaultProps = {
}

export default Page
